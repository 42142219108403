<!-- 전송페이지 공통 -->
<template>
    <div class="container">
        <div class="floating__save">
            <div class="save-btn"><button type="button"><img src="../../assets/resource/icon/save-btn.png" alt="중간저장" @click="save"></button></div>
        </div>
        <div class="sub-tit">
            <!--<h2></h2>-->
        </div>
        <div class="inner">
            <div>
                <div class="join-tab">
                    <ul class="join-tab__list">
                        <li class="join-tab__item active">
                            <div class="step"><span class="text">step </span><span class="num">01</span></div>
                            <div class="tit">개인정보입력</div>
                        </li>
                        <li class="join-tab__item">
                            <div class="step"><span class="text">step </span><span class="num">02</span></div>
                            <div class="tit">학력 및 경력사항 등 입력</div>
                        </li>
                        <li class="join-tab__item ">
                            <div class="step"><span class="text">step </span><span class="num">03</span></div>
                            <div class="tit">자기소개서 작성</div>
                        </li>
                        <li class="join-tab__item ">
                            <div class="step"><span class="text">step </span><span class="num">04</span></div>
                            <div class="tit">입사지원 완료</div>
                        </li>
                    </ul>
                </div>
                <div class="input-form type1">
                    <div class="text-box type1 info-text mt20">
                        <div class="input-form__top-wrap">
                            <p class="input-form__top-text type2">- 개인정보 보호를 위해 중간저장 후 일정시간<b class="c-red">(60분)</b> 동안 입력이 없을 경우 작성한 내용이 자동삭제 됩니다.</p>
                            <p class="input-form__top-text type2">- 지원서 작성 시 이전 페이지로 강제이동시 입력 오류가 발생할 수 있으니 주의 바랍니다.</p>
                        </div>
                    </div>
                </div>

                <form role="form" id="apllicantForm" name="apllicantForm" enctype="multipart/form-data" ref="applicantDiv">
                    <!-- 모집구분 -->
                    <div class="contents">
                        <div class="input-form type1 contents">
                            <div class="depth__wrap depth01">
                                <div class="depth__bullet"><span class="bullet01"></span></div>
                                <div class="depth__text">
                                    <h4 class="depth01__title">모집구분</h4>
                                </div>
                            </div>
                            <div class="table-cont">
                                <ul class="table-list type02">
                                    <input type="hidden" name="rnNo" v-model="applicantInfo.rnNo"/>
                                    <input type="hidden" name="raNo" v-model="applicantInfo.raNo"/>
                                    <input type="hidden" name="raAgree1" value="Y"/>
                                    <input type="hidden" name="raAgree2" v-model="raAgree2"/>
                                    <input type="hidden" name="raAgree3" v-model="raAgree3"/>
                                    <input type="hidden" name="raAgree4" value="Y"/>
                                    <input type="hidden" name="raAgree5" value="Y"/>
                                    <li class="table-item">
                                        <div class="item tit">채용공고</div>
                                        <div class="item">{{noticeInfo.rnTitle}}</div>
                                    </li>
                                    <li class="table-item">
                                        <div class="item tit">채용구분</div>
                                        <div class="item">{{$commonUtils.getRecruitCodeName('type', noticeInfo.rnType)}}</div>
                                    </li>
                                    <li class="table-item">
                                        <div class="item tit">부서</div>
                                        <div class="item">{{noticeInfo.rnDepartment}}</div>
                                    </li>
                                    <li class="table-item">
                                        <div class="item tit">직종</div>
                                        <div class="item" v-if="noticeInfo.rnCategoryDetail">[{{$commonUtils.getRecruitCodeName('category', noticeInfo.rnCategory)}}] {{$commonUtils.getRecruitCodeName('categoryDetail', noticeInfo.rnCategoryDetail)}}</div>
                                        <div class="item" v-else>{{$commonUtils.getRecruitCodeName('category', noticeInfo.rnCategory)}}</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <!-- 인적사항 -->
                    <div class="contents">
                        <div class="input-form type1 contents">
                            <div class="depth__wrap depth01">
                                <div class="depth__bullet"><span class="bullet01"></span></div>
                                <div class="depth__text">
                                    <h4 class="depth01__title">인적사항</h4>
                                </div>
                            </div>
                            <ul class="input-form__list" style="margin-top: 20px;">
                                <li class="input-form__item">
                                    <div class="input-form__box">
                                        <div class="tit"><span class="c-red">* </span>사진</div>
                                        <div class="cont">
                                            <div style="display: flex;">
                                                <label class="img-add">
                                                    <div class="img">
                                                        <img :src="imageSrc" alt="Selected Image" style="max-width: 177px; min-width: 177px; height: 207px; object-fit: cover;" />
                                                    </div>
                                                    <div class="btn">
                                                        <input type="file" name="uploader" @change="previewImage">찾아보기
                                                    </div>
                                                </label>
                                                <div style="margin-left: 20px;">
                                                    <p class="desc">1. JPG 파일만 가능합니다.</p>
                                                    <p class="desc">2. 파일크기는 1000KB 이하이어야 합니다.</p>
                                                    <p class="desc">3. jpg 이미지 사이즈 : 3x4 cm or 115x150 px</p>
                                                    <p class="desc">4. 6개월 이내 반명함판 사진으로 첨부해 주십시오.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="input-form__item">
                                    <div class="input-form__box">
                                        <div class="tit"><span class="c-red">* </span>성명(한글)</div>
                                        <div class="cont">
                                            <div>
                                                <input type="text" class="cont__text-input" name="raName" v-model="applicantInfo.raName" @change="changeDuplication" @input="$commonUtils.inputXssReplace($event)" ref="raName" maxlength="30">
                                            </div>
                                            <p class="desc">- 키보드가 영문 입력상태일 경우 한/영 전환버튼을 눌러 한글 전환 후 입력 해주시기 바랍니다.</p>
                                        </div>
                                    </div>
                                </li>
                                <li class="input-form__item item2">
                                    <div class="input-form__box">
                                        <div class="tit"><span class="c-red">* </span>성명(영문)</div>
                                        <div class="cont row">
                                            <span class="line" style="margin-right: 10px;">성</span>
                                            <input type="text" class="cont__text-input" name="raEngName1" v-model="applicantInfo.raEngName1" maxlength="50" @input="$commonUtils.inputXssReplace($event)">
                                            <span class="line" style="margin-right: 10px;">이름</span>
                                            <input type="text" class="cont__text-input" name="raEngName2" v-model="applicantInfo.raEngName2" maxlength="50" @input="$commonUtils.inputXssReplace($event)">
                                        </div>
                                    </div>
                                    <div class="input-form__box">
                                        <div class="tit"><span class="c-red">* </span>성별</div>
                                        <div class="cont">
                                            <div class="input-form__radio">
                                                <label class="input-item">
                                                    <input type="radio" class="radio" value="M" name="raGender" v-model="applicantInfo.raGender">
                                                    <span class="icon"></span>
                                                    남
                                                </label>
                                                <label class="input-item">
                                                    <input type="radio" class="radio" value="F" name="raGender" v-model="applicantInfo.raGender">
                                                    <span class="icon"></span>
                                                    여
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="input-form__item">
                                    <div class="input-form__box">
                                        <div class="tit"><span class="c-red">* </span>생년월일</div>
                                        <div class="cont">
                                            <div class="date_wrap" style="margin: 0;">
                                                <div class="date-input" ref="raBirth">
                                                    <!--<DatePicker v-model="applicantInfo.raBirth" :format="dateFormat" input-class="cont__text-input" locale="ko" :select-text="'선택'" :cancel-text="'취소'"
                                                                  week-start="0" :flow="['year', 'month', 'calendar']" :model-type="dateFormat" :enable-time-picker="false" auto-apply year-first/>-->
                                                    <DatePicker v-model="applicantInfo.raBirth" :format="dateFormat" input-class="cont__text-input" locale="ko" :select-text="'선택'" :cancel-text="'취소'"
                                                                  week-start="0" :model-type="dateFormat" :enable-time-picker="false" auto-apply year-first @update:model-value="changeDuplication" :flow="['year', 'month', 'calendar']"/>
                                                    <img src="../../assets/resource/icon/date_icon.png" alt="아이콘" />
                                                    <input type="hidden" name="raBirth" :value="this.applicantInfo.raBirth"/>
                                                </div>
                                            </div>
                                            <p class="desc">- 주민등록상의 8자리 생년월일을 입력하여 주시기 바랍니다.ex)1990-12-12</p>
                                        </div>
                                    </div>
                                </li>
                                <li class="input-form__item">
                                    <div class="input-form__box phon">
                                        <div class="tit"><span class="c-red">* </span>휴대전화</div>
                                        <div class="cont">
                                            <div style="display: flex; align-items: center;">
                                                <input type="text" class="cont__text-input" name="raHpNo1" v-model="raHpNo1" @change="changeDuplication" @input="$commonUtils.numberOnly" maxlength="3" ref="raHpNo1"> <span class="line">-</span>
                                                <input type="text" class="cont__text-input" name="raHpNo2" v-model="raHpNo2" @change="changeDuplication" @input="$commonUtils.numberOnly" maxlength="4" ref="raHpNo2"> <span class="line">-</span>
                                                <input type="text" class="cont__text-input" name="raHpNo3" v-model="raHpNo3" @change="changeDuplication" @input="$commonUtils.numberOnly" maxlength="4" ref="raHpNo3">
                                                <!--<input type="hidden" name="raHpNo" :value="applicantInfo.raHpNo1 + applicantInfo.raHpNo2 + applicantInfo.raHpNo3"/>-->
                                                <input type="hidden" name="raHpNo" :value="applicantInfo.raHpNo"/>
                                            </div>
                                            <p class="desc">"-"없이 숫자만 입력해주시기 바랍니다. ex)01012345678</p>
                                        </div>
                                    </div>
                                </li>
                                <li class="input-form__item">
                                    <div class="input-form__box email">
                                        <div class="tit"><span class="c-red">* </span>이메일</div>
                                        <div class="cont">
                                            <div style="display: flex; align-items: center;">
                                                <input type="text" class="cont__text-input" name="raEmail1" v-model="raEmail1" maxlength="50"> <span class="line">@</span>
                                                <input type="text" class="cont__text-input" name="raEmail2" v-model="raEmail2" @input="raEmail2 !== raEmail3 ? raEmail3 = '': ''" validType="domain" maxlength="100">
                                                <select class="cont__select" v-model="raEmail3" @change="raEmail2 = raEmail3" emptyValid>
                                                    <option value="">직접입력</option>
                                                    <option value="naver.com">naver.com</option>
                                                    <option value="daum.net">daum.net</option>
                                                    <option value="gmail.com">gmail.com</option>
                                                    <option value="yahoo.com">yahoo.com</option>
                                                    <option value="nate.com">nate.com</option>
                                                    <option value="korea.com">korea.com</option>
                                                    <option value="chol.com">chol.com</option>
                                                </select>
                                                <!--<input type="hidden" name="raEmail" :value="applicantInfo.raEmail1 + '@' + applicantInfo.raEmail2"/>-->
                                                <input type="hidden" name="raEmail" :value="applicantInfo.raEmail"/>
                                            </div>
                                            <p class="desc">- 비밀번호 분실 시 입력하신 이메일 주소로 비밀번호 찾기 결과가 전송되오니 정확하게 입력하시기 바랍니다.</p>
                                        </div>
                                    </div>
                                </li>

                                <li class="input-form__item">
                                    <div class="input-form__box">
                                        <div class="tit"><span class="c-red">* </span>비밀번호</div>
                                        <div class="cont">
                                            <div class="password" :class="checkPassYn === 'Y' ? 'active' : ''">
                                                <input type="password" class="cont__text-input" name="raPwd" v-model="applicantInfo.raPwd" @keyup="confirmPw" :emptyValid="this.updateYn === 'Y' ? true : null" maxlength="12">
                                            </div>
                                            <p class="desc">영문,숫자,특수문자 조합 8~12자리 사용 가능, 연속번호는 사용금지</p>
                                            <p class="desc">특수문자 사용 가능 범위 : ~ ! @ # $ % ^ & * _ -</p>
                                            <p class="desc">동일문자 연속 4개 사용금지</p>
                                            <p class="desc">아이디와 동일한 문구 사용금지</p>
                                            <br>
                                            <p class="desc">※ 해당 비밀번호는 입사지원서 수정 및 합격자 결과 확인시 필요합니다.</p>
                                            <p class="desc">※ 입사지원서 수정은 접수기간 동안 가능합니다.</p>
                                        </div>
                                    </div>
                                </li>
                                <li class="input-form__item">
                                    <div class="input-form__box">
                                        <div class="tit"><span class="c-red">* </span>비밀번호 확인</div>
                                        <div class="cont">
                                            <div class="password" :class="applicantInfo.raPwd && applicantInfo.raPwd2 && applicantInfo.raPwd === applicantInfo.raPwd2 ? 'active' : ''">
                                                <input type="password" class="cont__text-input" v-model="applicantInfo.raPwd2" :emptyValid="this.updateYn === 'Y' ? true : null" maxlength="12">
                                            </div>
                                            <p class="desc c-red" v-if="applicantInfo.raPwd && applicantInfo.raPwd2 && applicantInfo.raPwd !== applicantInfo.raPwd2">비밀번호가 일치하지 않습니다.</p>
                                        </div>
                                    </div>
                                </li>

                            </ul>
                        </div>
                        <div class="contents btn-wrap center" ref="duplicateSection" v-if="checkDuplicateYn === 'N'">
                            <a href="javascript:;" class="btn" @click="checkDuplication">중복지원 확인(필수)</a>
                        </div>
                    </div>

                    <!-- 기본정보 -->
                    <div class="contents">
                        <div class="input-form type1 contents">
                            <div class="depth__wrap depth01">
                                <div class="depth__bullet"><span class="bullet01"></span></div>
                                <div class="depth__text">
                                    <h4 class="depth01__title">기본정보</h4>
                                </div>
                            </div>
                            <ul class="input-form__list" style="margin-top: 20px;">
                                <li class="input-form__item">
                                    <div class="input-form__box">
                                        <div class="tit"><span class="c-red">* </span>응급연락망</div>
                                        <div class="cont">
                                            <div>
                                                <input type="text" class="cont__text-input" style="max-width: 35%" name="raEmerHpNo" v-model="applicantInfo.raEmerHpNo" maxlength="50" @input="$commonUtils.numberOnly">
                                                &nbsp;&nbsp;&nbsp;(관계 : <input type="text" class="cont__text-input" style="max-width: 8.2vw;" name="raEmerRel" v-model="applicantInfo.raEmerRel" maxlength="50" @input="$commonUtils.inputXssReplace($event)">)
                                            </div>
                                            <p class="desc">"-"없이 숫자만 입력해주시기 바랍니다. ex)01012345678 또는 0212341234</p>
                                        </div>
                                    </div>
                                </li>
                                <li class="input-form__item">
                                    <div class="input-form__box address">
                                        <div class="tit"><span class="c-red">* </span>주민등록지 <br>
                                            (등본주소지)
                                        </div>
                                        <div class="cont">
                                            <div class="cont-item">
                                                <input type="text" class="cont__text-input" name="raZipcode" v-model="applicantInfo.raZipcode" readonly @click="zipcodeApi">
                                                <button type="button" class="btn" @click="zipcodeApi">우편번호 검색</button>
                                            </div>
                                            <div class="cont-item">
                                                <input type="text" class="cont__text-input" name="raAddress1" v-model="applicantInfo.raAddress1" readonly>
                                            </div>
                                            <div class="cont-item">
                                                <input type="text" class="cont__text-input" name="raAddress2" v-model="applicantInfo.raAddress2" maxlength="50" @input="$commonUtils.inputXssReplace($event)">
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="input-form__item">
                                    <div class="input-form__box address">
                                        <div class="tit"><span class="c-red">* </span>현주소
                                        </div>
                                        <div class="cont">
                                            <div class="input-form__radio" style="margin-bottom: 10px;">
                                                <label class="input-item" style="cursor: pointer;">
                                                    <input type="checkbox" class="radio" name="raCDulpYn" v-model="applicantInfo.raCDulpYn" true-value="Y" false-value="N" @change="setDuplAddress">
                                                    <span class="icon"></span>
                                                    주민등록지 정보와 동일
                                                </label>
                                            </div>
                                            <div class="cont-item">
                                                <input type="text" class="cont__text-input" name="raCZipcode" v-model="applicantInfo.raCZipcode" readonly @click="zipcodeApi('current')">
                                                <button type="button" class="btn" @click="zipcodeApi('current')">우편번호 검색</button>
                                            </div>
                                            <div class="cont-item">
                                                <input type="text" class="cont__text-input" name="raCAddress1" v-model="applicantInfo.raCAddress1" readonly>
                                            </div>
                                            <div class="cont-item">
                                                <input type="text" class="cont__text-input" name="raCAddress2" v-model="applicantInfo.raCAddress2" maxlength="50" @input="$commonUtils.inputXssReplace($event)">
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="input-form__item item2">
                                    <div class="input-form__box">
                                        <div class="tit">취미</div>
                                        <div class="cont row">
                                            <input type="text" class="cont__text-input" name="raHobby" v-model="applicantInfo.raHobby" emptyValid :disabled="raAgree2==='N' ? true : null" maxlength="50" @input="$commonUtils.inputXssReplace($event)">
                                        </div>
                                    </div>
                                    <div class="input-form__box">
                                        <div class="tit">특기</div>
                                        <div class="cont row">
                                            <input type="text" class="cont__text-input" name="raSpecialty" v-model="applicantInfo.raSpecialty" emptyValid :disabled="raAgree2==='N' ? true : null" maxlength="50" @input="$commonUtils.inputXssReplace($event)">
                                        </div>
                                    </div>
                                </li>

                            </ul>
                        </div>
                    </div>

                    <!-- 보훈사항 -->
                    <div class="contents">
                        <div class="input-form type1 contents">
                            <div class="depth__wrap depth01">
                                <div class="depth__bullet"><span class="bullet01"></span></div>
                                <div class="depth__text">
                                    <h4 class="depth01__title">보훈사항</h4>
                                </div>
                            </div>
                            <ul class="input-form__list" style="margin-top: 20px;">
                                <li class="input-form__item item2">
                                    <div class="input-form__box">
                                        <div class="tit"><span class="c-red">* </span>보훈대상여부</div>
                                        <div class="cont radio">
                                            <div class="input-form__radio">
                                                <label class="input-item">
                                                    <input type="radio" class="radio" value="Y" name="raVeteranYn" v-model="applicantInfo.raVeteranYn" @change="changeRaVeteranYn" :disabled="raAgree3 === 'N' ? true : null">
                                                    <span class="icon"></span>
                                                    유
                                                </label>
                                                <label class="input-item">
                                                    <input type="radio" class="radio" value="N" name="raVeteranYn" v-model="applicantInfo.raVeteranYn" @change="changeRaVeteranYn">
                                                    <span class="icon"></span>
                                                    무
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="input-form__box email">
                                        <div class="tit"><span class="c-red">* </span>보훈구분</div>
                                        <div class="cont row">
                                            <select class="cont__select full" title="보훈구분" name="raVeteranType" v-model="applicantInfo.raVeteranType"
                                                    :emptyValid="applicantInfo.raVeteranYn === 'N' ? true : null" :disabled="applicantInfo.raVeteranYn === 'N' ? true : null">
                                                <option value="">선택</option>
                                                <option value="무공수훈자">무공수훈자</option>
                                                <option value="공상군경">공상군경</option>
                                                <option value="특원(반상)">특원(반상)</option>
                                                <option value="전상군경">전상군경</option>
                                                <option value="순국선열">순국선열</option>
                                                <option value="애국지사">애국지사</option>
                                                <option value="전몰군경">전몰군경</option>
                                                <option value="순직군경">순직군경</option>
                                                <option value="6.25참전재일학도의용공인">6.25참전재일학도의용공인</option>
                                                <option value="4.19혁명사망자">4.19혁명사망자</option>
                                                <option value="4.19혁명부상자">4.19혁명부상자</option>
                                                <option value="순직공무원">순직공무원</option>
                                                <option value="공상공무원">공상공무원</option>
                                                <option value="특별공로순직자">특별공로순직자</option>
                                                <option value="특별공로상이자">특별공로상이자</option>
                                                <option value="국가사회발전특별공로자">국가사회발전특별공로자</option>
                                                <option value="기타">기타</option>
                                                <option value="상이군경">상이군경</option>
                                                <option value="유족">유족</option>
                                                <option value="전상유족">전상유족</option>
                                            </select>
                                        </div>
                                    </div>
                                </li>
                                <li class="input-form__item item2">
                                    <div class="input-form__box">
                                        <div class="tit"><span class="c-red">* </span>보훈번호(숫자6자리)</div>
                                        <div class="cont row">
                                            <input type="text" class="cont__text-input" name="raVeteranSn" v-model="applicantInfo.raVeteranSn"
                                                   :emptyValid="applicantInfo.raVeteranYn === 'N' ? true : null" :disabled="applicantInfo.raVeteranYn === 'N' ? true : null" maxlength="6" @input="$commonUtils.numberOnly">
                                        </div>
                                    </div>
                                    <div class="input-form__box email">
                                        <div class="tit"><span class="c-red">* </span>유족관계</div>
                                        <div class="cont row">
                                            <select class="cont__select full" title="유족관계" name="raVeteranRel" v-model="applicantInfo.raVeteranRel"
                                                    :emptyValid="applicantInfo.raVeteranYn === 'N' ? true : null" :disabled="applicantInfo.raVeteranYn === 'N' ? true : null">
                                                <option value="">선택</option>
                                                <option value="고조부">고조부</option><option value="고조모">고조모</option>
                                                <option value="증조부">증조부</option>
                                                <option value="증조모">증조모</option>
                                                <option value="조부">조부</option>
                                                <option value="조모">조모</option>
                                                <option value="백부">백부</option>
                                                <option value="백모">백모</option>
                                                <option value="부">부</option>
                                                <option value="배)외조부">배)외조부</option>
                                                <option value="배)외조모">배)외조모</option>
                                                <option value="계부">계부</option>
                                                <option value="계모">계모</option>
                                                <option value="모">모</option>
                                                <option value="숙부">숙부</option>
                                                <option value="숙모">숙모</option>
                                                <option value="고모">고모</option>
                                                <option value="고모부">고모부</option>
                                                <option value="외숙부">외숙부</option>
                                                <option value="이모">이모</option>
                                                <option value="이모부">이모부</option>
                                                <option value="본인">본인</option>
                                                <option value="자매">자매</option>
                                                <option value="형제">형제</option>
                                                <option value="종형제">종형제</option>
                                                <option value="고종사촌">고종사촌</option>
                                                <option value="외사촌">외사촌</option>
                                                <option value="이종사촌">이종사촌</option>
                                                <option value="자">자</option>
                                                <option value="조카">조카</option>
                                                <option value="생질">생질</option>
                                                <option value="손">손</option>
                                                <option value="종손">종손</option>
                                                <option value="시부">시부</option>
                                                <option value="시모">시모</option>
                                                <option value="장인">장인</option>
                                                <option value="장모">장모</option>
                                                <option value="처">처</option>
                                                <option value="남편">남편</option>
                                                <option value="외숙모">외숙모</option>
                                                <option value="형부">형부</option>
                                                <option value="처제">처제</option>
                                                <option value="처남">처남</option>
                                                <option value="시백부">시백부</option>
                                                <option value="시백모">시백모</option>
                                                <option value="시숙부">시숙부</option>
                                                <option value="시숙모">시숙모</option>
                                                <option value="시동생">시동생</option>
                                                <option value="시누이">시누이</option>
                                                <option value="동서">동서</option>
                                                <option value="올케">올케</option>
                                                <option value="자부">자부</option>
                                                <option value="질부">질부</option>
                                                <option value="사위">사위</option>
                                                <option value="손자녀">손자녀</option>
                                                <option value="친척">친척</option>
                                                <option value="지인">지인</option>
                                                <option value="스승">스승</option>
                                                <option value="지도교수">지도교수</option>
                                                <option value="학과장">학과장</option>
                                                <option value="신부">신부</option>
                                                <option value="외조부">외조부</option>
                                                <option value="외조모">외조모</option>
                                                <option value="형수">형수</option>
                                                <option value="매형">매형</option>
                                                <option value="배)조부">배)조부</option>
                                                <option value="배)조모">배)조모</option>
                                                <option value="손부">손부</option>
                                                <option value="제수">제수</option>
                                                <option value="제부">제부</option>
                                                <option value="남동생">남동생</option>
                                                <option value="누나(언니)">누나(언니)</option>
                                                <option value="동문">동문</option>
                                                <option value="딸">딸</option>
                                                <option value="매">매</option>
                                                <option value="매부">매부</option>
                                                <option value="며느리">며느리</option>
                                                <option value="배)매">배)매</option>
                                                <option value="배)모">배)모</option>
                                                <option value="배)부">배)부</option>
                                                <option value="배)자">배)자</option>
                                                <option value="배)제">배)제</option>
                                                <option value="배)형">배)형</option>
                                                <option value="배우자">배우자</option>
                                                <option value="사촌">사촌</option>
                                                <option value="손녀">손녀</option>
                                                <option value="손자">손자</option>
                                                <option value="아들">아들</option>
                                                <option value="여동생">여동생</option>
                                                <option value="외손녀">외손녀</option>
                                                <option value="외손자">외손자</option>
                                                <option value="자형">자형</option>
                                                <option value="제">제</option>
                                                <option value="친지">친지</option>
                                                <option value="형">형</option>
                                                <option value="처형">처형</option>
                                                <option value="은사">은사</option>
                                                <option value="백숙부모">백숙부모</option>
                                                <option value="외숙부모">외숙부모</option>
                                                <option value="기타">기타</option>
                                            </select>
                                        </div>
                                    </div>
                                </li>

                            </ul>
                        </div>
                    </div>

                    <!-- 병역사항 -->
                    <div class="contents">
                        <div class="input-form type1 contents">
                            <div class="depth__wrap depth01">
                                <div class="depth__bullet"><span class="bullet01"></span></div>
                                <div class="depth__text">
                                    <h4 class="depth01__title">병역사항</h4>
                                </div>
                            </div>
                            <ul class="input-form__list" style="margin-top: 20px;">
                                <li class="input-form__item item2">
                                    <div class="input-form__box">
                                        <div class="tit"><span class="c-red">* </span>병역사항 이행여부</div>
                                        <div class="cont radio">
                                            <div class="input-form__radio">
                                                <label class="input-item">
                                                    <input type="radio" class="radio" value="Y" name="raMilitaryYn" v-model="applicantInfo.raMilitaryYn" @change="changeRaMilitaryYn">
                                                    <span class="icon"></span>
                                                    유
                                                </label>
                                                <label class="input-item">
                                                    <input type="radio" class="radio" value="N" name="raMilitaryYn" v-model="applicantInfo.raMilitaryYn" @change="changeRaMilitaryYn">
                                                    <span class="icon"></span>
                                                    무
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="input-form__box">
                                        <div class="tit"><span class="c-red">* </span>면제사유</div>
                                        <div class="cont row">
                                            <input type="text" class="cont__text-input" name="raMilitaryExemption" v-model="applicantInfo.raMilitaryExemption" @input="$commonUtils.inputXssReplace($event)"
                                                   :emptyValid="(applicantInfo.raMilitaryYn !== 'N' || applicantInfo.raGender === 'F') ? true : null" :disabled="applicantInfo.raMilitaryYn === 'Y' ? true : null" maxlength="100">
                                        </div>
                                    </div>
                                </li>
                                <li class="input-form__item item2">
                                    <div class="input-form__box">
                                        <div class="tit"><span class="c-red">* </span>복무기간</div>
                                        <div class="cont row">
                                            <div class="date_wrap" style="flex-wrap: nowrap; margin: 0;">
                                                <div class="date-input" style="width: 170px;">
                                                    <DatePicker v-model="applicantInfo.raMilitaryStartD" :format="dateFormat" input-class="cont__text-input" locale="ko" :select-text="'선택'" :cancel-text="'취소'"
                                                                week-start="0" :model-type="dateFormat" :enable-time-picker="false" auto-apply year-first :max-date="applicantInfo.raMilitaryEndD"
                                                                :emptyValid="applicantInfo.raMilitaryYn === 'N' ? true : null" :disabled="applicantInfo.raMilitaryYn === 'N' ? true : null" :flow="['year', 'month', 'calendar']"/>
                                                    <img src="../../assets/resource/icon/date_icon.png" alt="아이콘" />
                                                    <input type="hidden" name="raMilitaryStartD" :value="applicantInfo.raMilitaryStartD"/>
                                                </div>
                                                <span class="line" style="margin: 0 10px;">~</span>
                                                <div class="date-input" style="width: 170px;">
                                                    <DatePicker v-model="applicantInfo.raMilitaryEndD" :format="dateFormat" input-class="cont__text-input" locale="ko" :select-text="'선택'" :cancel-text="'취소'"
                                                                week-start="0" :model-type="dateFormat" :enable-time-picker="false" auto-apply year-first :min-date="applicantInfo.raMilitaryStartD"
                                                                :emptyValid="applicantInfo.raMilitaryYn === 'N' ? true : null" :disabled="applicantInfo.raMilitaryYn === 'N' ? true : null" :flow="['year', 'month', 'calendar']"/>
                                                    <img src="../../assets/resource/icon/date_icon.png" alt="아이콘" />
                                                    <input type="hidden" name="raMilitaryEndD" :value="applicantInfo.raMilitaryEndD"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="input-form__box email">
                                        <div class="tit"><span class="c-red">* </span>군별</div>
                                        <div class="cont row">
                                            <select class="cont__select full" title="군별" name="raMilitarySpecialty" v-model="applicantInfo.raMilitarySpecialty"
                                                    :emptyValid="applicantInfo.raMilitaryYn === 'N' ? true : null" :disabled="applicantInfo.raMilitaryYn === 'N' ? true : null">
                                                <option value="">선택</option>
                                                <option value="육군">육군</option>
                                                <option value="해군">해군</option>
                                                <option value="공군">공군</option>
                                                <option value="전투경찰">전투경찰</option>
                                                <option value="해양경찰">해양경찰</option>
                                                <option value="의무경찰">의무경찰</option>
                                                <option value="해병대">해병대</option>
                                                <option value="공익근무">공익근무</option>
                                                <option value="방위">방위</option>
                                                <option value="공중보건의">공중보건의</option>
                                                <option value="기타">기타</option>
                                            </select>
                                        </div>
                                    </div>
                                </li>
                                <li class="input-form__item item3">
                                    <div class="input-form__box email">
                                        <div class="tit"><span class="c-red">* </span>역종</div>
                                        <div class="cont row">
                                            <select class="cont__select full" title="역종" name="raMilitaryService" v-model="applicantInfo.raMilitaryService"
                                                    :emptyValid="applicantInfo.raMilitaryYn === 'N' ? true : null" :disabled="applicantInfo.raMilitaryYn === 'N' ? true : null">
                                                <option value="">선택</option>
                                                <option value="예비역">예비역</option>
                                                <option value="보충역">보충역</option>
                                                <option value="국민역">국민역</option>
                                                <option value="미필">미필</option>
                                                <option value="면제">면제</option>
                                                <option value="제대예정자">제대예정자</option>
                                                <option value="미필보충역">미필보충역</option>
                                                <option value="실역필보충">실역필보충</option>
                                                <option value="제１국민역">제１국민역</option>
                                                <option value="제２국민역">제２국민역</option>
                                                <option value="특례보충역">특례보충역</option>
                                                <option value="동원지정">동원지정</option>
                                                <option value="동원미지정">동원미지정</option>
                                                <option value="일반예비군">일반예비군</option>
                                                <option value="민방위">민방위</option>
                                                <option value="기타">기타</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="input-form__box email">
                                        <div class="tit"><span class="c-red">* </span>병과</div>
                                        <div class="cont row">
                                            <select class="cont__select full" title="병과" name="raMilitaryOrdnance" v-model="applicantInfo.raMilitaryOrdnance"
                                                    :emptyValid="applicantInfo.raMilitaryYn === 'N' ? true : null" :disabled="applicantInfo.raMilitaryYn === 'N' ? true : null">
                                                <option value="">선택</option>
                                                <option value="보병">보병</option>
                                                <option value="포병">포병</option>
                                                <option value="기갑">기갑</option>
                                                <option value="공병">공병</option>
                                                <option value="통신">통신</option>
                                                <option value="병기">병기</option>
                                                <option value="병참">병참</option>
                                                <option value="수송">수송</option>
                                                <option value="화학">화학</option>
                                                <option value="부관 (행정)">부관 (행정)</option>
                                                <option value="헌병">헌병</option>
                                                <option value="경리">경리</option>
                                                <option value="정훈">정훈</option>
                                                <option value="군의">군의</option>
                                                <option value="치의">치의</option>
                                                <option value="의정">의정</option>
                                                <option value="군종">군종</option>
                                                <option value="법무">법무</option>
                                                <option value="군악">군악</option>
                                                <option value="간호">간호</option>
                                                <option value="전투">전투</option>
                                                <option value="기관">기관</option>
                                                <option value="기술">기술</option>
                                                <option value="시설">시설</option>
                                                <option value="일반">일반</option>
                                                <option value="보급">보급</option>
                                                <option value="조종">조종</option>
                                                <option value="정비">정비</option>
                                                <option value="취사">취사</option>
                                                <option value="레이다">레이다</option>
                                                <option value="암호">암호</option>
                                                <option value="전문">전문</option>
                                                <option value="여군">여군</option>
                                                <option value="위생">위생</option>
                                                <option value="의무">의무</option>
                                                <option value="전산">전산</option>
                                                <option value="전투경찰">전투경찰</option>
                                                <option value="(해,공군)기술">(해,공군)기술</option>
                                                <option value="(해,공군)전문">(해,공군)전문</option>
                                                <option value="(해,공군)전투">(해,공군)전투</option>
                                                <option value="(해군)함정기관">(해군)함정기관</option>
                                                <option value="(해병대)보급">(해병대)보급</option>
                                                <option value="공중전문의">공중전문의</option>
                                                <option value="기상">기상</option>
                                                <option value="발전">발전</option>
                                                <option value="부안">부안</option>
                                                <option value="소총">소총</option>
                                                <option value="운전">운전</option>
                                                <option value="원호">원호</option>
                                                <option value="유선">유선</option>
                                                <option value="작전">작전</option>
                                                <option value="정보,보안">정보,보안</option>
                                                <option value="탄약">탄약</option>
                                                <option value="특전">특전</option>
                                                <option value="항공">항공</option>
                                                <option value="항공포병">항공포병</option>
                                                <option value="행정">행정</option>
                                                <option value="FM 장비운용">FM 장비운용</option>
                                                <option value="조정">조정</option>
                                                <option value="기타">기타</option>

                                            </select>
                                        </div>
                                    </div>
                                    <div class="input-form__box email">
                                        <div class="tit"><span class="c-red">* </span>계급</div>
                                        <div class="cont row">
                                            <select class="cont__select full" title="계급" name="raMilitaryRank" v-model="applicantInfo.raMilitaryRank"
                                                    :emptyValid="applicantInfo.raMilitaryYn === 'N' ? true : null" :disabled="applicantInfo.raMilitaryYn === 'N' ? true : null">
                                                <option value="">선택</option>
                                                <option value="대장">대장</option>
                                                <option value="중장">중장</option>
                                                <option value="소장">소장</option>
                                                <option value="준장">준장</option>
                                                <option value="대령">대령</option>
                                                <option value="중령">중령</option>
                                                <option value="소령">소령</option>
                                                <option value="대위">대위</option>
                                                <option value="중위">중위</option>
                                                <option value="소위">소위</option>
                                                <option value="준위">준위</option>
                                                <option value="상사">상사</option>
                                                <option value="중사">중사</option>
                                                <option value="장기하사">장기하사</option>
                                                <option value="단기하사">단기하사</option>
                                                <option value="일반하사">일반하사</option>
                                                <option value="하사">하사</option>
                                                <option value="일반중사">일반중사</option>
                                                <option value="병장">병장</option>
                                                <option value="상병">상병</option>
                                                <option value="일병">일병</option>
                                                <option value="이병">이병</option>
                                                <option value="방위병">방위병</option>
                                                <option value="제2 국민병">제2 국민병</option>
                                                <option value="원사">원사</option>
                                                <option value="일등상사">일등상사</option>
                                                <option value="이등상사">이등상사</option>
                                                <option value="사병">사병</option>
                                                <option value="영관">영관</option>
                                                <option value="하관">하관</option>
                                                <option value="기타">기타</option>
                                            </select>
                                        </div>
                                    </div>
                                </li>
                                <li class="input-form__item item3">
                                    <div class="input-form__box email">
                                        <div class="tit"><span class="c-red">* </span>예비군</div>
                                        <div class="cont radio">
                                            <div class="input-form__radio">
                                                <label class="input-item">
                                                    <input type="radio" class="radio" value="Y" name="raMilitaryReserveYn" v-model="applicantInfo.raMilitaryReserveYn"
                                                           :emptyValid="applicantInfo.raMilitaryYn === 'N' ? true : null" :disabled="applicantInfo.raMilitaryYn === 'N' ? true : null">
                                                    <span class="icon"></span>
                                                    유
                                                </label>
                                                <label class="input-item">
                                                    <input type="radio" class="radio" value="N" name="raMilitaryReserveYn" v-model="applicantInfo.raMilitaryReserveYn"
                                                           :emptyValid="applicantInfo.raMilitaryYn === 'N' ? true : null" :disabled="applicantInfo.raMilitaryYn === 'N' ? true : null">
                                                    <span class="icon"></span>
                                                    무
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="input-form__box email">
                                        <div class="tit"><span class="c-red">* </span>민방위</div>
                                        <div class="cont radio">
                                            <div class="input-form__radio">
                                                <label class="input-item">
                                                    <input type="radio" class="radio" value="Y" name="raMilitaryCd" v-model="applicantInfo.raMilitaryCd"
                                                           :emptyValid="applicantInfo.raMilitaryYn === 'N' ? true : null" :disabled="applicantInfo.raMilitaryYn === 'N' ? true : null">
                                                    <span class="icon"></span>
                                                    유
                                                </label>
                                                <label class="input-item">
                                                    <input type="radio" class="radio" value="N" name="raMilitaryCd" v-model="applicantInfo.raMilitaryCd"
                                                           :emptyValid="applicantInfo.raMilitaryYn === 'N' ? true : null" :disabled="applicantInfo.raMilitaryYn === 'N' ? true : null">
                                                    <span class="icon"></span>
                                                    무
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="input-form__box email">
                                        <div class="tit"><span class="c-red">* </span>제대구분</div>
                                        <div class="cont row">
                                            <select class="cont__select full" title="제대구분" name="raMilitaryDischarge" v-model="applicantInfo.raMilitaryDischarge"
                                                    :emptyValid="applicantInfo.raMilitaryYn === 'N' ? true : null" :disabled="applicantInfo.raMilitaryYn === 'N' ? true : null">
                                                <option value="">선택</option>
                                                <option value="만기제대">만기제대</option>
                                                <option value="소집해제">소집해제</option>
                                                <option value="의가사제대">의가사제대</option>
                                                <option value="의병제대">의병제대</option>
                                                <option value="명예제대">명예제대</option>
                                                <option value="불명예제대">불명예제대</option>
                                                <option value="상이제대">상이제대</option>
                                                <option value="방위소집해제">방위소집해제</option>
                                                <option value="예편">예편</option>
                                                <option value="신검불합격">신검불합격</option>
                                                <option value="특례보충역">특례보충역</option>
                                                <option value="징집면제">징집면제</option>
                                                <option value="병역면제(기타)">병역면제(기타)</option>
                                                <option value="병역미필">병역미필</option>
                                                <option value="보충역필">보충역필</option>
                                                <option value="예편">예편</option>
                                                <option value="의원">의원</option>
                                                <option value="소집면제">소집면제</option>
                                                <option value="제대예정">제대예정</option>
                                                <option value="기타">기타</option>
                                            </select>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <!-- 기타 -->
                    <div class="contents">
                        <div class="input-form type1 contents">
                            <div class="depth__wrap depth01">
                                <div class="depth__bullet"><span class="bullet01"></span></div>
                                <div class="depth__text">
                                    <h4 class="depth01__title">기타</h4>
                                </div>
                            </div>
                            <ul class="input-form__list" style="margin-top: 20px;">
                                <li class="input-form__item">
                                    <div class="input-form__box">
                                        <div class="tit"><span class="c-red">* </span>장애여부</div>
                                        <div class="cont radio">
                                            <div class="input-form__radio">
                                                <label class="input-item">
                                                    <input type="radio" class="radio" value="Y" name="raDisabilityYn" v-model="applicantInfo.raDisabilityYn" @change="changeRaDisabilityYn" :disabled="raAgree3 === 'N' ? true : null">
                                                    <span class="icon"></span>
                                                    유
                                                </label>
                                                <label class="input-item">
                                                    <input type="radio" class="radio" value="N" name="raDisabilityYn" v-model="applicantInfo.raDisabilityYn" @change="changeRaDisabilityYn">
                                                    <span class="icon"></span>
                                                    무
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="input-form__item item3">
                                    <div class="input-form__box">
                                        <div class="tit"><span class="c-red">* </span>장애번호</div>
                                        <div class="cont row">
                                            <input type="text" class="cont__text-input" name="raDisabilitySn" v-model="applicantInfo.raDisabilitySn" @input="$commonUtils.inputXssReplace($event)"
                                                   :emptyValid="applicantInfo.raDisabilityYn === 'N' ? true : null" :disabled="applicantInfo.raDisabilityYn === 'N' ? true : null" maxlength="50">
                                        </div>
                                    </div>
                                    <div class="input-form__box email">
                                        <div class="tit"><span class="c-red">* </span>장애정도</div>
                                        <div class="cont row">
                                            <select class="cont__select full" title="장애정도선택" name="raDisabilityDegree" v-model="applicantInfo.raDisabilityDegree"
                                                    :emptyValid="applicantInfo.raDisabilityYn === 'N' ? true : null" :disabled="applicantInfo.raDisabilityYn === 'N' ? true : null">
                                                <option value="">선택</option>
                                                <option value="경증">경증</option>
                                                <option value="중증">중증</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="input-form__box">
                                        <div class="tit"><span class="c-red">* </span>장애부위</div>
                                        <div class="cont row">
                                            <input type="text" class="cont__text-input" name="raDisabilityArea" v-model="applicantInfo.raDisabilityArea" @input="$commonUtils.inputXssReplace($event)"
                                                   :emptyValid="applicantInfo.raDisabilityYn === 'N' ? true : null" :disabled="applicantInfo.raDisabilityYn === 'N' ? true : null" maxlength="50">
                                        </div>
                                    </div>
                                </li>

                            </ul>
                        </div>
                        <div class="contents btn-wrap center">
                            <a href="javascript:;" class="btn" @click="save">중간저장</a>
                            <a href="javascript:;" class="btn blue" @click="goNextStep">중간저장 및 다음단계</a>
                        </div>
                    </div>
                </form>
                <div id="zipcodeWrap" style="display:none;">
                    <div ref="embed"
                         style="display: block; position: fixed; overflow: hidden; z-index: 1; width: 500px; height: 500px; border: 3px solid; left: 509.5px; top: 281.5px;">
                        <div style="height:30px;background:white;">
                            <button id="btnCloseLayer" @click="closeDaumPostcode()"  style="cursor:pointer;position:absolute;right:-3px;top:-3px;z-index:1; height: 20px; width: 20px;padding: 0;border: none;">
                                x
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <ExtentionTimePopup ref="extentionTimePopup" :raNo="this.raNo"></ExtentionTimePopup>

        </div>
    </div>
</template>

<script>
import DatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import ExtentionTimePopup from "@/components/common/ExtentionTimePopup.vue";

export default {
        components: {
            DatePicker,
            ExtentionTimePopup,
        },
        data() {
            return {
                imageSrc: require('@/assets/resource/img/img-add.png'),
                dateFormat: 'yyyy-MM-dd', // 날짜 형식

                rnNo: this.$route.query.rnNo,
                // raNo: this.$route.query.raNo,
                raNo: '',
                type: this.$route.query.type,
                noticeInfo: {},
                applicantInfo: {},
                raBirth: '',
                raMilitaryStartD: '',
                raMilitaryEndD: '',
                raHpNo1: '',
                raHpNo2: '',
                raHpNo3: '',
                raEmail1: '',
                raEmail2: '',
                raEmail3: '',
                checkDuplicateYn: 'N',
                checkDuplicateName: '',
                checkDuplicateBirth: '',
                checkDuplicateHpNo: '',
                checkPassYn: '',
                updateYn: '',

                raAgree2: this.$store.state.applicantStore.raAgree2,
                raAgree3: this.$store.state.applicantStore.raAgree3,
            };
        },
        watch: {
            raHpNo1: {
                handler(newData) {
                    if (newData && this.raHpNo1 && this.raHpNo2 && this.raHpNo3) {
                        this.applicantInfo.raHpNo = this.raHpNo1 + this.raHpNo2 + this.raHpNo3
                    } else {
                        this.applicantInfo.raHpNo = ''
                    }
                },
                deep: true,
                immediate: true
            },
            raHpNo2: {
                handler(newData) {
                    if (newData && this.raHpNo1 && this.raHpNo2 && this.raHpNo3) {
                        this.applicantInfo.raHpNo = this.raHpNo1 + this.raHpNo2 + this.raHpNo3
                    } else {
                        this.applicantInfo.raHpNo = ''
                    }
                },
                deep: true,
                immediate: true
            },
            raHpNo3: {
                handler(newData) {
                    if (newData && this.raHpNo1 && this.raHpNo2 && this.raHpNo3) {
                        this.applicantInfo.raHpNo = this.raHpNo1 + this.raHpNo2 + this.raHpNo3
                    } else {
                        this.applicantInfo.raHpNo = ''
                    }
                },
                deep: true,
                immediate: true
            },
            raEmail1: {
                handler(newData) {
                    if (newData && this.raEmail1 && this.raEmail2) {
                        this.applicantInfo.raEmail = this.raEmail1 + '@' + this.raEmail2
                    } else {
                        this.applicantInfo.raEmail = ''
                    }
                },
                deep: true,
                immediate: true
            },
            raEmail2: {
                handler(newData) {
                    if (newData && this.raEmail1 && this.raEmail2) {
                        this.applicantInfo.raEmail = this.raEmail1 + '@' + this.raEmail2
                    } else {
                        this.applicantInfo.raEmail = ''
                    }
                },
                deep: true,
                immediate: true
            },
        },
        methods: {
            previewImage(event) {
                const file = event.target.files[0];
                if (file && file.type.startsWith('image/jp') && file.size <= 1000000) { // 1000KB 이하
                    const reader = new FileReader();
                    reader.onload = e => {
                        this.imageSrc = e.target.result;
                    };
                    reader.readAsDataURL(file);
                } else {
                    alert('유효하지 않은 파일입니다. JPG 파일만 가능하며 크기는 1000KB 이하이어야 합니다.');
                }
            },
            // 우편번호 찾기 api
            zipcodeApi(addressType) {
                const zipcodeWrap = document.getElementById('zipcodeWrap');
                zipcodeWrap.style.display = 'block';
                new window.daum.Postcode({
                    oncomplete: (data) => {
                        // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.

                        // 도로명 주소의 노출 규칙에 따라 주소를 조합한다.
                        // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
                        let fullRoadAddr = data.roadAddress; // 도로명 주소 변수
                        let extraRoadAddr = ''; // 도로명 조합형 주소 변수

                        // 법정동명이 있을 경우 추가한다. (법정리는 제외)
                        // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
                        if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
                            extraRoadAddr += data.bname;
                        }
                        // 건물명이 있고, 공동주택일 경우 추가한다.
                        if (data.buildingName !== '' && data.apartment === 'Y') {
                            extraRoadAddr += (extraRoadAddr !== '' ? ', ' + data.buildingName : data.buildingName);
                        }
                        // 도로명, 지번 조합형 주소가 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
                        if (extraRoadAddr !== '') {
                            extraRoadAddr = ' (' + extraRoadAddr + ')';
                        }
                        // 도로명, 지번 주소의 유무에 따라 해당 조합형 주소를 추가한다.
                        if (fullRoadAddr !== '') {
                            fullRoadAddr += extraRoadAddr;
                        }

                        // 우편번호와 주소 정보를 해당 필드에 넣는다.
                        if (addressType === 'current') {
                            this.applicantInfo.raCZipcode = data.zonecode;
                            this.applicantInfo.raCAddress1 = fullRoadAddr;
                        } else {
                            this.applicantInfo.raZipcode = data.zonecode;
                            this.applicantInfo.raAddress1 = fullRoadAddr;
                        }

                        // 주소 정보 클릭하면 팝업 close
                        this.closeDaumPostcode();
                    }
                }).embed(this.$refs.embed);
                //.open(); 사용시 팝업창 생성
            },
            closeDaumPostcode() {
                const zipcodeWrap = document.getElementById('zipcodeWrap');
                zipcodeWrap.style.display = 'none';
            },
            setDuplAddress() {
                this.$nextTick(() => {
                    if (this.applicantInfo.raCDulpYn === 'Y') {
                        this.applicantInfo.raCZipcode = this.applicantInfo.raZipcode
                        this.applicantInfo.raCAddress1 = this.applicantInfo.raAddress1
                        this.applicantInfo.raCAddress2 = this.applicantInfo.raAddress2
                    } else if (this.applicantInfo.raCDulpYn !== 'Y') {
                        this.applicantInfo.raCZipcode = ''
                        this.applicantInfo.raCAddress1 = ''
                        this.applicantInfo.raCAddress2 = ''
                    }
                })
            },
            confirmPw(event) {
                let pass = event ? event.target.value : this.applicantInfo.raPwd
                const reg = /^(?=.*[a-zA-Z])(?=.*[~!@#$%^&*_-])(?=.*[0-9]).{8,12}$/; // 영문, 숫자, 특수문자 인식
                const regNum = /(012)|(123)|(234)|(345)|(456)|(567)|(678)|(789)|(987)|(876)|(765)|(654)|(543)|(432)|(321)/; // 연속 숫자
                const regAlp = /(abc)|(bcd)|(cde)|(def)|(efg)|(fgh)|(ghi)|(hij)|(ijk)|(jkl)|(klm)|(lmn)|(mno)|(nop)|(opq)|(pqr)|(qrs)|(rst)|(stu)|(tuv)|(uvw)|(vwx)|(wxy)|(xyz)|(zyx)|(yxw)|(xwv)|(wvu)|(vut)|(uts)|(tsr)|(srq)|(rqp)|(qpo)|(pon)|(onm)|(nml)|(mlk)|(lkj)|(kji)|(jih)|(ihg)|(hgf)|(gfe)|(fed)|(edc)|(dcb)|(cba)/; // 연속 문자
                const sameCheck = /([0-9a-zA-Z])\1{3,}/; // 동일문자 연속 4개 이상 확인

                if (pass == '') {
                    this.checkPassYn = ''
                }else if (reg.test(pass) && !regNum.test(pass) && !regAlp.test(pass) && !sameCheck.test(pass) && pass.indexOf(this.memId) < 0) {
                    this.checkPassYn = 'Y'
                } else {
                    this.checkPassYn = 'N'
                }
            },
            getRecruitNoticeView() {
                this.axios
                    .get(this.api.recruit.notice.view, {
                        params: {
                            rnNo: this.rnNo,
                            updateCntYn: 'N'
                        }
                    })
                    .then((res) => {
                        this.noticeInfo = res.data
                        this.applicantInfo.rnNo = this.noticeInfo.rnNo
                        if(this.noticeInfo == '' || !this.$commonUtils.isExpose(this.noticeInfo.exposeEndDt)){
                            alert('종료되었거나 조회되지 않는 게시글입니다.')
                            this.$router.push('/')
                        }
                    });
            },
            async getRecruitApplicantView() {
                await this.axios.get(this.api.recruit.applicant.view, {
                    params: {
                        raNo: this.raNo,
                    }
                }).then(res => {
                    // console.log(res.data)
                    if (res.data) {
                        this.updateYn = 'Y'
                        this.applicantInfo = res.data
                        if (this.applicantInfo.raHpNo) {
                            this.raHpNo1 = this.applicantInfo.raHpNo.substring(0, 3)
                            this.raHpNo2 = this.applicantInfo.raHpNo.substring(3, 7)
                            this.raHpNo3 = this.applicantInfo.raHpNo.substring(7)
                        }
                        if (this.applicantInfo.raEmail) {
                            this.raEmail1 = this.applicantInfo.raEmail.split('@')[0]
                            this.raEmail2 = this.applicantInfo.raEmail.split('@')[1]
                        }
                        if (this.applicantInfo.raPwd) {
                            // this.confirmPw()
                            // this.applicantInfo.raPwd2 = this.applicantInfo.raPwd
                            this.applicantInfo.raPwd = ''
                            this.applicantInfo.raPwd2 = ''
                            this.checkDuplicateYn = 'Y'
                            this.checkDuplicateName = this.applicantInfo.raName
                            this.checkDuplicateBirth = this.applicantInfo.raBirth
                            this.checkDuplicateHpNo = this.applicantInfo.raHpNo
                        }
                        if (this.applicantInfo.attachBag && this.applicantInfo.attachBag.profile) {
                            this.applicantInfo.attachBag.profile[0]
                            this.imageSrc = "/displayFile.do?name="+this.applicantInfo.attachBag.profile[0].savedName
                        }
                        if (this.applicantInfo.raAgree2) {
                            this.raAgree2 = this.applicantInfo.raAgree2
                        }
                        if (this.applicantInfo.raAgree3) {
                            this.raAgree3 = this.applicantInfo.raAgree3
                        }

                        if (!this.applicantInfo.submitDt) { // 중간 저장 후 55분 경과시 연장팝업 노출
                            this.$refs.extentionTimePopup.setModifyTime(this.applicantInfo.modifiedDt)
                        }
                    } else {
                        alert("해당 지원정보가 없습니다.")
                    }
                });
            },
            checkDuplication() {
                if (!this.applicantInfo.raName) {
                    this.$refs.raName.scrollIntoView({ block: 'center'})
                    setTimeout(() => {
                        this.$refs.raName.focus()
                    }, 100)
                    alert(`[성명(한글)] 항목을 입력해주세요.`);
                    return
                }
                if (!this.applicantInfo.raBirth) {
                    this.$refs.raBirth.children[0].querySelector('input').scrollIntoView({ block: 'center'})
                    setTimeout(() => {
                        this.$refs.raBirth.children[0].querySelector('input').focus()
                    }, 100)
                    alert(`[생년월일] 항목을 입력해주세요.`);
                    return
                }
                if (!this.raHpNo1) {
                    this.$refs.raHpNo1.scrollIntoView({ block: 'center'})
                    setTimeout(() => {
                        this.$refs.raHpNo1.focus()
                    }, 100)
                    alert(`[휴대전화] 항목을 입력해주세요.`);
                    return
                }
                if (!this.raHpNo2) {
                    this.$refs.raHpNo2.scrollIntoView({ block: 'center'})
                    setTimeout(() => {
                        this.$refs.raHpNo2.focus()
                    }, 100)
                    alert(`[휴대전화] 항목을 입력해주세요.`);
                    return
                }
                if (!this.raHpNo3) {
                    this.$refs.raHpNo3.scrollIntoView({ block: 'center'})
                    setTimeout(() => {
                        this.$refs.raHpNo3.focus()
                    }, 100)
                    alert(`[휴대전화] 항목을 입력해주세요.`);
                    return
                }

                this.checkDuplicateYn = 'N'
                this.axios
                    .get(this.api.recruit.applicant.checkDuplicate, {
                        params: {
                            rnNo: this.rnNo,
                            raName: this.applicantInfo.raName,
                            raBirth: this.applicantInfo.raBirth,
                            raHpNo: this.applicantInfo.raHpNo,
                        }
                    })
                    .then((res) => {
                        if(res.data.code == '0000'){
                            alert(res.data.message);
                            this.checkDuplicateYn = 'Y'
                            this.checkDuplicateName = this.applicantInfo.raName
                            this.checkDuplicateBirth = this.applicantInfo.raBirth
                            this.checkDuplicateHpNo = this.applicantInfo.raHpNo
                        }else{
                            alert(res.data.message);
                        }
                    });
            },
            changeDuplication() {
                if (this.checkDuplicateName && this.checkDuplicateBirth && this.checkDuplicateHpNo) {
                    if (this.checkDuplicateName !== this.applicantInfo.raName) {
                        this.checkDuplicateYn = 'N'
                    } else if (this.checkDuplicateBirth !== this.applicantInfo.raBirth) {
                        this.checkDuplicateYn = 'N'
                    } else if (this.checkDuplicateHpNo !== this.applicantInfo.raHpNo) {
                        this.checkDuplicateYn = 'N'
                    } else {
                        this.checkDuplicateYn = 'Y'
                    }
                }
            },
            changeRaVeteranYn() {
                this.$nextTick(() => {
                    if (this.applicantInfo.raVeteranYn === 'N') {
                        this.applicantInfo._raVeteranType = this.applicantInfo.raVeteranType
                        this.applicantInfo._raVeteranSn = this.applicantInfo.raVeteranSn
                        this.applicantInfo._raVeteranRel = this.applicantInfo.raVeteranRel

                        this.applicantInfo.raVeteranType = ''
                        this.applicantInfo.raVeteranSn = ''
                        this.applicantInfo.raVeteranRel = ''
                    } else {
                        this.applicantInfo.raVeteranType = this.applicantInfo._raVeteranType
                        this.applicantInfo.raVeteranSn = this.applicantInfo._raVeteranSn
                        this.applicantInfo.raVeteranRel = this.applicantInfo._raVeteranRel

                        this.applicantInfo._raVeteranType = ''
                        this.applicantInfo._raVeteranSn = ''
                        this.applicantInfo._raVeteranRel = ''
                    }
                })
            },
            changeRaMilitaryYn() {
                if (this.applicantInfo.raMilitaryYn === 'N') {
                    this.applicantInfo.raMilitaryExemption = this.applicantInfo._raMilitaryExemption
                    this.applicantInfo._raMilitaryStartD = this.applicantInfo.raMilitaryStartD
                    this.applicantInfo._raMilitaryEndD = this.applicantInfo.raMilitaryEndD
                    this.applicantInfo._raMilitarySpecialty = this.applicantInfo.raMilitarySpecialty
                    this.applicantInfo._raMilitaryService = this.applicantInfo.raMilitaryService
                    this.applicantInfo._raMilitaryOrdnance = this.applicantInfo.raMilitaryOrdnance
                    this.applicantInfo._raMilitaryRank = this.applicantInfo.raMilitaryRank
                    this.applicantInfo._raMilitaryReserveYn = this.applicantInfo.raMilitaryReserveYn
                    this.applicantInfo._raMilitaryCd = this.applicantInfo.raMilitaryCd
                    this.applicantInfo._raMilitaryDischarge = this.applicantInfo.raMilitaryDischarge

                    this.applicantInfo._raMilitaryExemption = ''
                    this.applicantInfo.raMilitaryStartD = ''
                    this.applicantInfo.raMilitaryEndD = ''
                    this.applicantInfo.raMilitarySpecialty = ''
                    this.applicantInfo.raMilitaryService = ''
                    this.applicantInfo.raMilitaryOrdnance = ''
                    this.applicantInfo.raMilitaryRank = ''
                    this.applicantInfo.raMilitaryReserveYn = ''
                    this.applicantInfo.raMilitaryCd = ''
                    this.applicantInfo.raMilitaryDischarge = ''
                } else {
                    this.applicantInfo._raMilitaryExemption = this.applicantInfo.raMilitaryExemption
                    this.applicantInfo.raMilitaryStartD = this.applicantInfo._raMilitaryStartD
                    this.applicantInfo.raMilitaryEndD = this.applicantInfo._raMilitaryEndD
                    this.applicantInfo.raMilitarySpecialty = this.applicantInfo._raMilitarySpecialty
                    this.applicantInfo.raMilitaryService = this.applicantInfo._raMilitaryService
                    this.applicantInfo.raMilitaryOrdnance = this.applicantInfo._raMilitaryOrdnance
                    this.applicantInfo.raMilitaryRank = this.applicantInfo._raMilitaryRank
                    this.applicantInfo.raMilitaryReserveYn = this.applicantInfo._raMilitaryReserveYn
                    this.applicantInfo.raMilitaryCd = this.applicantInfo._raMilitaryCd
                    this.applicantInfo.raMilitaryDischarge = this.applicantInfo._raMilitaryDischarge

                    this.applicantInfo.raMilitaryExemption = ''
                    this.applicantInfo._raMilitaryStartD = ''
                    this.applicantInfo._raMilitaryEndD = ''
                    this.applicantInfo._raMilitarySpecialty = ''
                    this.applicantInfo._raMilitaryService = ''
                    this.applicantInfo._raMilitaryOrdnance = ''
                    this.applicantInfo._raMilitaryRank = ''
                    this.applicantInfo._raMilitaryReserveYn = ''
                    this.applicantInfo._raMilitaryCd = ''
                    this.applicantInfo._raMilitaryDischarge = ''
                }
            },
            changeRaDisabilityYn() {
                if (this.applicantInfo.raDisabilityYn === 'N') {
                    this.applicantInfo._raDisabilitySn = this.applicantInfo.raDisabilitySn
                    this.applicantInfo._raDisabilityDegree = this.applicantInfo.raDisabilityDegree
                    this.applicantInfo._raDisabilityArea = this.applicantInfo.raDisabilityArea

                    this.applicantInfo.raDisabilitySn = ''
                    this.applicantInfo.raDisabilityDegree = ''
                    this.applicantInfo.raDisabilityArea = ''
                } else {
                    this.applicantInfo.raDisabilitySn = this.applicantInfo._raDisabilitySn
                    this.applicantInfo.raDisabilityDegree = this.applicantInfo._raDisabilityDegree
                    this.applicantInfo.raDisabilityArea = this.applicantInfo._raDisabilityArea

                    this.applicantInfo._raDisabilitySn = ''
                    this.applicantInfo._raDisabilityDegree = ''
                    this.applicantInfo._raDisabilityArea = ''
                }
            },
            submit(mode) {
                let formData = new FormData(document.getElementById('apllicantForm'));
                formData.set('raPwd', btoa(this.applicantInfo.raPwd))
                formData.set('raCDulpYn', this.applicantInfo.raCDulpYn ? this.applicantInfo.raCDulpYn : 'N')
                this.axios.post(this.api.recruit.applicant.register, formData)
                    .then(res => {
                        console.log("res.data: " + res.data);
                        console.log("res data2: "+JSON.stringify(res.data))
                        if(res.data.code == '0000'){
                            // 입력시간연장
                            if (this.raNo && !this.applicantInfo.submitDt) {
                                this.$refs.extentionTimePopup.extensionTime()
                            }

                            if (mode === 'goNextStep') {
                                // alert('지원자의 개인정보가 저장되었습니다.');
                                this.$store.commit('setRaNo', res.data.raNo)
                                this.$router.push({ path: '/application/applicationStep2.do', query: {rnNo: this.rnNo, type: 'update'}})
                            } else {
                                alert('중간저장이 완료되었습니다.');
                            }
                        }else{
                            alert(res.data.message); //res.data.message
                            if(res.data.code == '0001'){
                                this.$router.push('/')
                            }
                        }
                    })
            },
            save() {
                if (this.checkDuplicateYn !== 'Y') {
                    this.$refs.duplicateSection.scrollIntoView({ block: 'center'})
                    alert("중복지원확인을 해주세요.");

                    return
                }

                this.submit()
            },
            goNextStep() {
                if (this.checkDuplicateYn !== 'Y') {
                    this.$refs.duplicateSection.scrollIntoView({ block: 'center'})
                    alert("중복지원확인을 해주세요.");

                    return
                }

                this.$nextTick( () => {
                    if (!this.isInputValidation(this.$refs.applicantDiv)) {
                        return
                    }

                    this.submit('goNextStep')
                });
            },
            isInputValidation(component) {

                let isValid = true;
                const invalidFields = [];

                const findInvalidFields = (component) => {
                    if (component.children) {
                        for (var i = 0; i < component.children.length; i++) {

                            if (component.children[i].readOnly || component.children[i].disabled || (component.children[i].attributes.emptyValid && !component.children[i].value)) {
                                continue
                            }
                            if (component.children[i].tagName === 'INPUT') {
                                if (component.children[i].type === 'radio') {
                                    if (component.children[i].attributes.emptyValid) {
                                        continue
                                    }
                                    if (!this.applicantInfo[component.children[i].name]) {
                                        invalidFields.push(component.children[i].closest('div'));
                                        continue
                                    }
                                } else if (component.children[i].type === 'text') {
                                    if (!component.children[i].value || component.children[i].value === '') {
                                        // if (component.children[i].attributes["data-test"] && component.children[i].attributes["data-test"].value === 'dp-input') {
                                        //     invalidFields.push(component.children[i].closest('div.dp__main'));
                                        // } else {
                                        //     invalidFields.push(component.children[i]);
                                        // }
                                        invalidFields.push(component.children[i]);
                                        continue
                                    }
                                    if (component.children[i].attributes.validType) {
                                        if (component.children[i].attributes.validType.value === 'email' && !this.$commonUtils.validateEmail(component.children[i].value)) {
                                            component.children[i].validMsg = 'validType'
                                            invalidFields.push(component.children[i]);
                                        } else if (component.children[i].attributes.validType.value === 'domain' && !this.$commonUtils.validateDomail(component.children[i].value)) {
                                            component.children[i].validMsg = 'validType'
                                            invalidFields.push(component.children[i]);
                                        } else if (component.children[i].attributes.validType.value === 'tel' && !this.$commonUtils.validateTel(component.children[i].value)) {
                                            component.children[i].validMsg = 'validType'
                                            invalidFields.push(component.children[i]);
                                        }
                                    }
                                } else if (component.children[i].type === 'password') {
                                    if (!component.children[i].value || component.children[i].value === '') {
                                        invalidFields.push(component.children[i]);
                                        continue
                                    } else {
                                        if (component.children[i].name === 'raPwd' && this.checkPassYn !== 'Y') {
                                            component.children[i].validMsg = 'validType'
                                            invalidFields.push(component.children[i]);
                                            continue
                                        }
                                        if (component.children[i].name !== 'raPwd' && this.applicantInfo.raPwd != this.applicantInfo.raPwd2) {
                                            component.children[i].validMsg = 'validType'
                                            invalidFields.push(component.children[i]);
                                            continue
                                        }
                                    }
                                } else if (component.children[i].type === 'file') {
                                    if (component.children[i].closest('.img-add').querySelector('img').attributes.src.value === require('@/assets/resource/img/img-add.png')) {
                                        invalidFields.push(component.children[i].closest('.img-add'));
                                        continue
                                    }
                                }
                            } else if (component.children[i].tagName === 'TEXTAREA') {
                                if (!component.children[i].value || component.children[i].value === '') {
                                    invalidFields.push(component.children[i]);
                                    continue
                                }
                                if (component.children[i].minLength > 0 && component.children[i].minLength > component.children[i].value.length) {
                                    component.children[i].validMsg = 'minLength'
                                    invalidFields.push(component.children[i]);
                                    continue
                                }
                            } else if (component.children[i].tagName === 'SELECT') {
                                if (!component.children[i].value || component.children[i].value === '') {
                                    invalidFields.push(component.children[i]);
                                    continue
                                }
                            }
                            findInvalidFields(component.children[i]);
                        }
                    }
                };

                findInvalidFields(component);

                if (invalidFields.length > 0) {
                    // console.log(invalidFields[0])

                    invalidFields[0].scrollIntoView({ block: 'center' })
                    setTimeout(() => {
                        invalidFields[0].focus();
                    }, 100)

                    let objNm = invalidFields[0].closest('div.input-form__box').querySelector('div.tit').textContent;

                    objNm = objNm.replace('* ', '').trim();
                    objNm = objNm.replace('\n', '');
                    objNm = objNm.replace(/ +/g, " ");

                    if (invalidFields[0].validMsg) {
                        alert(`[${objNm}] 항목이 유효하지 않습니다. 다시 확인해주세요.`); //
                    } else {
                        alert(`[${objNm}] 항목을 입력해주세요.`); //
                    }

                    isValid = false;
                }

                return isValid;
            },
        },
        mounted() {

            setTimeout(() => {
                gsap.to('.floating__save', {scrollTrigger: {trigger: '.container',start: 'bottom-=0px bottom',toggleClass: { targets: '.floating__save', className: 'on' },scrub: true}});
            }, 300);


            this.raNo = this.$store.state.applicantStore.raNo;
            // this.$store.commit('setRaNo', null)

            if ((!this.rnNo) || (this.type === 'update' && !this.raNo)) {
                alert("올바르지 않은 접근입니다.")
                this.$router.push({ path: '/application/editApplication.do'})
            }

            if (this.rnNo) {
                this.getRecruitNoticeView()
            }

            if (this.raNo) {
                this.getRecruitApplicantView()
            }

            if (this.raAgree3 === 'N') {
                this.applicantInfo.raVeteranYn = 'N'
                this.applicantInfo.raDisabilityYn = 'N'
            }
        }
    };
</script>